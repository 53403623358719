import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Pages
    {
      name: "Home",
      path: "/",
      component: () => import("./src/pages/Home"),
    },

    {
      name: "SportNews",
      path: "/SportNews",
      component: () => import("./src/pages/SportNews"),
    },
    {
      name: "DetailNews",
      path: "/DetailNews/:uniqueId?",
      component: () => import("./src/pages/DetailNews"),
    },
    {
      name: "CategoryList",
      path: "/:link?",
      component: () => import("./src/pages/CategoryList"),
    },
  ],
});

export default router;
