import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import firebase from "firebase/compat/app";
import firebaseConfig from "./firebase/firebaseConfig.js";
import router from "./../router";
import VueCarousel from "vue-carousel";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import VueAdsense from "vue-google-adsense";
Vue.component("vue-adsense", VueAdsense);
Vue.use(moment);
Vue.use(VueAxios, axios);
Vue.use(VueCarousel);
firebase.initializeApp(firebaseConfig);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
