<template>
  <div>
    <head>
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="stylesheet" href="assets/css/widgets.css" />
      <link rel="stylesheet" href="assets/css/color.css" />
      <link rel="stylesheet" href="assets/css/responsive.css" />
    </head>
    <header class="main-header header-style-2 mb-40">
      <div class="header-bottom header-sticky background-white text-center">
        <div class="mobile_menu d-lg-none d-block"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-md-3">
              <div class="header-logo d-none d-lg-block">
                <a href="/">
                  <img
                    class="logo-img d-inline"
                    src="assets/imgs/logo.png"
                    alt=""
                  />
                </a>
              </div>
              <div class="logo-tablet d-md-inline d-lg-none d-none">
                <a href="/">
                  <img
                    class="logo-img d-inline"
                    src="assets/imgs/logo.png"
                    alt=""
                  />
                </a>
              </div>
              <div
                class="logo-mobile d-block d-md-none"
                style="max-width: 250px !important"
              >
                <a href="/">
                  <img
                    class="logo-img d-inline"
                    src="assets/imgs/logo.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-10 col-md-9 main-header-navigation">
              <!-- Main-menu -->
              <div class="main-nav text-left float-lg-left float-md-right">
                <ul class="mobi-menu d-none menu-3-columns" id="navigation">
                  <li class="cat-item cat-item-2">
                    <a href="/">Actualités</a>
                  </li>
                  <li class="cat-item cat-item-4">
                    <a href="/societe">Société</a>
                  </li>
                  <li class="cat-item cat-item-5">
                    <a href="/international">Internationale</a>
                  </li>
                  <li class="cat-item cat-item-6">
                    <a href="/politique">Politique</a>
                  </li>
                  <li class="cat-item cat-item-7">
                    <a href="SportNews">Sport</a>
                  </li>
                  <li class="cat-item cat-item-2">
                    <a href="/economie">Economie</a>
                  </li>
                  <li class="cat-item cat-item-2">
                    <a href="/culture">Culture</a>
                  </li>
                  <li class="cat-item cat-item-2">
                    <a href="/sciences">Hi-tech et Sciences</a>
                  </li>
                  <li class="cat-item cat-item-3">
                    <a href="/celebrite">Célébrité</a>
                  </li>
                </ul>
                <nav>
                  <ul class="main-menu d-none d-lg-inline">
                    <li class="menu-item">
                      <a href="/"><span class="mr-15"> </span>Actualités</a>
                    </li>

                    <li class="menu-item">
                      <a
                        href="javascript:;"
                        @click="redirectToCategoryNews(societe)"
                        ><span class="mr-15"> </span>Société</a
                      >
                    </li>

                    <li>
                      <a
                        href="javascript:;"
                        @click="redirectToCategoryNews(international)"
                        >Internationale</a
                      >
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        @click="redirectToCategoryNews(politique)"
                        >Politique</a
                      >
                    </li>
                    <li>
                      <a href="SportNews">Sport</a>
                    </li>
                    <li class="mega-menu-item">
                      <a href="javascript:;"> Diversité </a>
                      <div class="sub-mega-menu">
                        <div class="nav flex-column nav-pills" role="tablist">
                          <a
                            class="nav-link active"
                            data-toggle="pill"
                            href="#news-0"
                            @click="redirectToCategoryNews(economie)"
                            role="tab"
                            >Economie</a
                          >
                          <a
                            class="nav-link"
                            data-toggle="pill"
                            href="#news-1"
                            role="tab"
                            @click="redirectToCategoryNews(culture)"
                            >Culture</a
                          >
                          <a
                            class="nav-link"
                            data-toggle="pill"
                            href="#news-2"
                            role="tab"
                            @click="redirectToCategoryNews(sciences)"
                            >Hi-tech et Sciences</a
                          >
                          <!--  <a
                            class="nav-link"
                            data-toggle="pill"
                            href="#news-3"
                            role="tab"
                            @click="redirectToCategoryNews(sciencelebriteces)"
                            >Célébrité</a
                          > -->
                        </div>
                        <div class="tab-content">
                          <div
                            class="tab-pane show active"
                            id="news-0"
                            role="tabpanel"
                          >
                            <div class="row">
                              <div
                                class="col-4 post-module-1"
                                v-for="article in EconomieNews"
                                :key="article.uniqueId"
                              >
                                <div
                                  class="post-thumb d-flex border-radius-5 img-hover-scale mb-15"
                                >
                                  <a>
                                    <img
                                      :src="article.image"
                                      alt=""
                                      v-on:error="article.image = MercaNews"
                                    />
                                  </a>
                                </div>
                                <div class="post-content media-body">
                                  <h6 class="post-title mb-10 text-limit-2-row">
                                    {{ article.title }}
                                  </h6>
                                  <div
                                    class="entry-meta meta-1 font-x-small color-grey mt-10"
                                  >
                                    <span class="post-on">{{
                                      formatTimeAgo(article.date)
                                    }}</span>
                                    <span class="hit-count has-dot">{{
                                      article.sourceName
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane" id="news-1" role="tabpanel">
                            <div class="row">
                              <div
                                class="col-4 post-module-1"
                                v-for="article in CultureNews"
                                :key="article.uniqueId"
                              >
                                <div
                                  class="post-thumb d-flex border-radius-5 img-hover-scale mb-15"
                                >
                                  <a>
                                    <img
                                      :src="article.image"
                                      alt=""
                                      v-on:error="article.image = MercaNews"
                                    />
                                  </a>
                                </div>
                                <div class="post-content media-body">
                                  <h6 class="post-title mb-10 text-limit-2-row">
                                    {{ article.title }}
                                  </h6>
                                  <div
                                    class="entry-meta meta-1 font-x-small color-grey mt-10"
                                  >
                                    <span class="post-on">{{
                                      formatTimeAgo(article.date)
                                    }}</span>
                                    <span class="hit-count has-dot">{{
                                      article.sourceName
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane" id="news-2" role="tabpanel">
                            <div class="row">
                              <div
                                class="col-4 post-module-1"
                                v-for="article in SciencesNews"
                                :key="article.uniqueId"
                              >
                                <div
                                  class="post-thumb d-flex border-radius-5 img-hover-scale mb-15"
                                >
                                  <a>
                                    <img
                                      :src="article.image"
                                      alt=""
                                      v-on:error="article.image = MercaNews"
                                    />
                                  </a>
                                </div>
                                <div class="post-content media-body">
                                  <h6 class="post-title mb-10 text-limit-2-row">
                                    {{ article.title }}
                                  </h6>
                                  <div
                                    class="entry-meta meta-1 font-x-small color-grey mt-10"
                                  >
                                    <span class="post-on">{{
                                      formatTimeAgo(article.date)
                                    }}</span>
                                    <span class="hit-count has-dot">{{
                                      article.sourceName
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane" id="news-3" role="tabpanel">
                            <div class="row">
                              <div
                                class="col-4 post-module-1"
                                v-for="article in CelebriteNews"
                                :key="article.uniqueId"
                              >
                                <div
                                  class="post-thumb d-flex border-radius-5 img-hover-scale mb-15"
                                >
                                  <a>
                                    <img
                                      :src="article.image"
                                      alt=""
                                      v-on:error="article.image = MercaNews"
                                    />
                                  </a>
                                </div>
                                <div class="post-content media-body">
                                  <h6 class="post-title mb-10 text-limit-2-row">
                                    {{ article.title }}
                                  </h6>
                                  <div
                                    class="entry-meta meta-1 font-x-small color-grey mt-10"
                                  >
                                    <span class="post-on">{{
                                      formatTimeAgo(article.date)
                                    }}</span>
                                    <span class="hit-count has-dot">{{
                                      article.sourceName
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- Search -->
              <!--
              <form
                action="#"
                method="get"
                class="search-form d-lg-inline float-right position-relative mr-30 d-none"
              >
                <input
                  type="text"
                  class="search_field"
                  placeholder="Search"
                  value=""
                  name="s"
                  v-model="searchTerm"
                  @blur="clearResults"
                />
                <span class="search-icon"><i class="ti-search mr-5"></i></span>
              </form> -->
              <!-- <div class="search-results" v-if="results.length">
                <ul>
                  <li v-for="result in results" :key="result.id">
                    <div class="row">
                      <div class="col-4">
                        <div class="img-hover-scale mb-15">
                          <a>
                            <img :src="result._source.image" alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="col-8">
                        <h4>{{ result._source.title }}</h4>
                        <p>{{ result._source.description }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div> -->
              <!-- Off canvas -->
              <div class="off-canvas-toggle-cover">
                <div
                  class="off-canvas-toggle hidden d-inline-block ml-15"
                  id="off-canvas-toggle"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import { formatTimeAgo } from "./../Function/function";
import axios from "axios";
import db from "../firebase/firebaseInit";
import MercaNews from "../../public/assets/imgs/Mercanews.png";

export default {
  components: {},

  data() {
    return {
      MercaNews: MercaNews,
      searchTerm: "",
      articles: [],
      apiUrl: "http://52.57.93.159:9200/rss_feed/_doc/_search",
      query: null,
      results: [],
      searchQuery: "",
      culture: "culture",
      sciences: "sciences",
      actualites: "actualites",
      societe: "societe",
      celebrite: "celebrite",
      economie: "economie",
      sport: "sport",
      international: "international",
      politique: "politique",
      CultureNews: [],
      EconomieNews: [],
      SciencesNews: [],
      CelebriteNews: [],
    };
  },
  methods: {
    formatTimeAgo,
    async getCultureNews() {
      await db
        .collection("rss_feed")
        .where("category", "==", "culture")
        .orderBy("isoDate", "desc")
        .limit(3)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.CultureNews.push({
              category: doc.data().libelle,
              title: doc.data().title,
              description: doc.data().description,
              sourceName: doc.data().sourceName,
              link: doc.data().link,
              uniqueId: doc.data().uniqueId,
              image: doc.data().image,
              date: doc.data().isoDate,
              language: doc.data().language,
            });
          });
        });
    },
    async getCelebriteNews() {
      await db
        .collection("rss_feed")
        .where("category", "==", "celebrite")
        .orderBy("isoDate", "desc")
        .limit(3)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.CelebriteNews.push({
              category: doc.data().libelle,
              title: doc.data().title,
              description: doc.data().description,
              sourceName: doc.data().sourceName,
              link: doc.data().link,
              uniqueId: doc.data().uniqueId,
              image: doc.data().image,
              date: doc.data().isoDate,
              language: doc.data().language,
            });
          });
        });
    },
    async getEconomieNews() {
      await db
        .collection("rss_feed")
        .where("category", "==", "economie")
        .orderBy("isoDate", "desc")
        .limit(3)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.EconomieNews.push({
              category: doc.data().libelle,
              title: doc.data().title,
              description: doc.data().description,
              sourceName: doc.data().sourceName,
              link: doc.data().link,
              uniqueId: doc.id,
              image: doc.data().image,
              date: doc.data().isoDate,
              language: doc.data().language,
            });
          });
        });
    },
    async getSciencesNews() {
      await db
        .collection("rss_feed")
        .where("category", "==", "sciences")
        .orderBy("isoDate", "desc")
        .limit(3)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.SciencesNews.push({
              category: doc.data().libelle,
              title: doc.data().title,
              description: doc.data().description,
              sourceName: doc.data().sourceName,
              link: doc.data().link,
              uniqueId: doc.data().uniqueId,
              image: doc.data().image,
              date: doc.data().isoDate,
              language: doc.data().language,
            });
          });
        });
    },

    redirectToCategoryNews(item) {
      const categoryLink = `/${item}`;
      this.$router.push({
        name: "CategoryList",
        params: { link: item },
      });
      window.location.href = categoryLink;
    },

    clearResults() {
      this.results = [];
    },
    searchArticles: function () {
      const query = {
        query: {
          match: {
            title: this.searchTerm,
          },
        },
      };
      axios
        .post(this.apiUrl, query)
        .then((response) => {
          this.articles = response.data.hits.hits.map((hit) => hit._source);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    searchTerm: function () {
      if (this.searchTerm.length >= 3) {
        this.searchArticles();
      } else {
        this.articles = [];
      }
    },
  },
  async created() {
    await this.getCultureNews();
    await this.getEconomieNews();
    await this.getSciencesNews();
    await this.getCelebriteNews();
  },
};
</script>
<style scoped>
.search-results {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  width: 60%;
  max-height: 400px;
  overflow-y: auto;
}

.search-results ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f5f5f5;
}
</style>
