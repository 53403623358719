import moment from "moment";
export function replaceImageUrls(articles) {
  for (let i = 0; i < articles.length; i++) {
    if (articles[i].image.includes("/small")) {
      articles[i].image = articles[i].image.replace("/small/", "/");
    } else if (articles[i].image.includes("/thumbnails_small")) {
      articles[i].image = articles[i].image.replace("/thumbnails_small", "");
    } else if (articles[i].image.includes("100x100")) {
      articles[i].image = articles[i].image.replace("100x100", "1000x1000");
    } else {
      articles[i].image = articles[i].image.replace(".jpg/305", ".jpg/");
    }
  }
}

export function redirectToDetailNews(item) {
  this.$router.push({
    name: "DetailNews",
    params: {
      uniqueId: item.uniqueId,
    },
  });
}
export function formatTimeAgo(date) {
  const now = moment();
  const then = moment(date.toDate());
  const diff = moment.duration(now.diff(then));

  if (diff.asSeconds() < 60) {
    return `il y a ${Math.floor(diff.asSeconds())} secondes`;
  } else if (diff.asMinutes() < 60) {
    return ` Il y a ${Math.floor(diff.asMinutes())} minutes`;
  } else if (diff.asHours() < 24) {
    if (diff.asHours() < 2) {
      return `il y a ${Math.floor(diff.asHours())} heure`;
    } else {
      return `il y a ${Math.floor(diff.asHours())} heures`;
    }
  } else if (diff.asDays() < 7) {
    if (diff.asDays() < 2) {
      return `il y a ${Math.floor(diff.asDays())} jour`;
    } else {
      return `il y a ${Math.floor(diff.asDays())} jours`;
    }
  } else if (diff.asDays() < 30) {
    if (diff.asDays() < 14) {
      return `il y a ${Math.floor(diff.asDays() / 7)} semaine`;
    } else {
      return `il y a ${Math.floor(diff.asDays() / 7)} semaines`;
    }
  } else if (diff.asDays() < 365) {
    return `il y a ${Math.floor(diff.asDays() / 30)} mois`;
  } else {
    return `il y a ${Math.floor(diff.asYears())} an`;
  }
}
